<template>
  <div class="card mb-4" v-if="this.$store.state.getMovie.status.tvShowLoading">
    <div class="card-header p-0 m-0">
      <img
        :src="imageSizeMovie('original') + tv.backdrop_path"
        class="img-fluid w-100"
        :alt="tv.original_name"
        :title="tv.original_name"
        v-if="tv.backdrop_path && !tv.adult"
      />
      <img
        src="../../assets/no_cover.jpg"
        class="img-fluid w-100"
        :alt="tv.original_title"
        :title="tv.original_name"
        v-else
      />
    </div>
    <div class="card-body">
      <h1 class="text-center" v-text="tv.name"></h1>
      <p class="text-center">
        وضعیت:<span class="ms-1 badge bg-primary">{{
          translatePersian(tv.status)
        }}</span>
      </p>
      <p class="text-center" dir="ltr">{{ tv.overview }}</p>
      <div
        class="d-flex flex-wrap align-content-center justify-content-between"
      >
        <card-box-data
          v-if="tv.genres.length > 0"
          title="ژانرها"
          color="bg-success"
        >
          <template v-slot:cardTitle>
            <span v-for="(genre, index) in tv.genres" :key="genre.id">
              {{ translatePersian(genre.name) }}
              <span v-if="index !== Object.keys(tv.genres).length - 1">- </span>
            </span>
          </template>
        </card-box-data>
        <card-box-data
          v-if="tv.created_by.length > 0"
          title="خلق شده توسط"
          color="bg-info"
        >
          <template v-slot:cardTitle>
            <span v-for="(person, index) in tv.created_by" :key="person.id">
              {{ person.name }}
              <span v-if="index !== Object.keys(tv.created_by).length - 1"
                >-
              </span>
            </span>
          </template>
        </card-box-data>
        <card-box-data title="نحوه اکران" color="bg-color-primary-3">
          <template v-slot:cardTitle>
            {{ tv.number_of_seasons }} فصل - {{ tv.number_of_episodes }} قسمت
          </template>
        </card-box-data>
        <card-box-data
          v-if="tv.production_companies[0]"
          title="کمپانی سازنده"
          color="bg-primary"
        >
          <template v-slot:cardTitle>
            <span
              v-for="(production_companie, index) in tv.production_companies"
              :key="production_companie.id"
            >
              {{ production_companie.name }}
              <span
                v-if="index !== Object.keys(tv.production_companies).length - 1"
                >-
              </span>
            </span>
          </template>
        </card-box-data>
        <card-box-data
          v-if="tv.production_countries[0]"
          title="کشور سازنده"
          color="bg-color-primary-4"
        >
          <template v-slot:cardTitle>
            {{ tv.production_countries[0].name }}
          </template>
        </card-box-data>
        <card-box-data
          v-if="tv.vote_count"
          title="تعداد رای"
          color="bg-secondary"
        >
          <template v-slot:cardTitle> {{ tv.vote_count }} رای </template>
        </card-box-data>
        <card-box-data
          v-if="tv.vote_average"
          title="میانگین امتیاز"
          color="bg-danger"
        >
          <template v-slot:cardTitle> {{ tv.vote_average }} از 10 </template>
        </card-box-data>
        <card-box-data
          v-if="tv.first_air_date"
          title="تاریخ اکران"
          color="bg-dark"
        >
          <template v-slot:cardTitle>
            {{ tv.first_air_date }}
          </template>
        </card-box-data>
        <card-box-data
          v-if="tv.networks.length > 0"
          title="شبکه پخش"
          color="bg-color-primary-2"
        >
          <template v-slot:cardTitle>
            <a
              :href="tv.homepage"
              target="_blank"
              class="text-white"
              v-if="tv.homepage"
            >
              {{ tv.networks[0].name }}
            </a>
            <p class="text-white" v-text="tv.imdb_id" v-else></p>
          </template>
        </card-box-data>
      </div>
    </div>
  </div>
  <div class="card" v-else>
    <spinner />
  </div>
</template>

<script>
import CardBoxData from "@/components/template/cardBoxData";
import Spinner from "@/components/template/spinner";
export default {
  name: "BioTv",
  components: { Spinner, CardBoxData },
  props: {
    tv: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped></style>
